import React, { forwardRef, useEffect, useState } from 'react';

const Videography = forwardRef((props, ref) => {

  const [hasAnimated, setHasAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setTimeout(() => setHasAnimated(true), 500);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, hasAnimated]);

  return (
    <div ref={ref} className={`w-full lg:h-[700px] h-[600px] px-12 bg-black transition-opacity duration-0 ${
      isVisible ? 'opacity-100 animate-fadeIn' : 'opacity-0'
    } ${hasAnimated ? 'opacity-100' : ''}`}>
      <div className='w-full h-[600px] max-w-[1200px] flex flex-col items-center justify-center gap-10 rounded-lg p-5 mx-auto'>
        <p className='text-white font-[gustavo-regular] text-2xl'>VIDEOGRAPHY</p>
        <p className='text-white font-[Montserrat] lg:text-xl text-sm mt-4 text-start'>
        Videography allows me to bring cars to life, capturing their power, elegance, and every meticulous detail in motion.<br></br> From iconic brands to hypercars <span className='text-[#208474]'>like Koenigsegg</span>, I’ve had the privilege of working with some of the world’s most extraordinary vehicles.<br></br> Let’s create a cinematic experience that elevates your car to its rightful stage.
        </p>
      </div>
    </div>
  )
});

export default Videography
