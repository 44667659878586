import React, { useState, useEffect } from "react";
import telefonkep from "../assets/haromphoneN3_low.png";

function PhoneScreen() {

  const [hasAnimated, setHasAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setTimeout(() => setHasAnimated(true), 500);
        }
      },
      { threshold: 0.5 }
    );

    const targetElement = document.querySelector("#phone-screen");
    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [hasAnimated]);

  return (
    <div id="phone-screen" className={`h-full w-full relative ${
      isVisible ? 'opacity-100 animate-fadeIn' : 'opacity-0'
    } ${hasAnimated ? 'opacity-100' : ''}`}>
      <img
        src={telefonkep}
        alt=""
        className="z-20 lg:h-[800px] h-[300px] object-cover mx-auto lg:mt-0 mt-10"
      />
    </div>
  );
}

export default PhoneScreen;
