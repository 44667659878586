import Aboutme from "./components/aboutme";
import Booking from "./components/Booking";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import PhoneScreen from "./components/PhoneScreen";
import Photography from "./components/Photography";
import Videography from "./components/Videography";
import React, { useRef } from 'react';

function App() {

  const bookingRef = useRef(null);
  const photographyRef = useRef(null);
  const videographyRef = useRef(null);
  const aboutMeRef = useRef(null);

  const scrollToAboutMe = () => {
    if (aboutMeRef.current) {
      const offset = window.innerWidth <= 768 ? 10 : 70;
      window.scrollTo({
        top: aboutMeRef.current.offsetTop - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollToBooking = () => {
    if (bookingRef.current) {
      window.scrollTo({
        top: bookingRef.current.offsetTop - 70,
        behavior: "smooth",
      });
    }
  };

  const scrollToPhotography = () => {
    if (photographyRef.current) {
      const offset = window.innerWidth <= 768 ? 100 : 230;
      window.scrollTo({
        top: photographyRef.current.offsetTop - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollToVideography = () => {
    if (videographyRef.current) {
      const offset = window.innerWidth <= 768 ? 30 : 140;
      window.scrollTo({
        top: videographyRef.current.offsetTop - offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="w-full">
      <Hero onScrollToAboutMe={scrollToAboutMe} onScrollToBooking={scrollToBooking} onScrollToPhotography={scrollToPhotography} onScrollToVideography={scrollToVideography}/>
      <Booking ref={bookingRef}/>
      <Photography ref={photographyRef}/>
      <PhoneScreen />
      <Videography ref={videographyRef}/>
      <Aboutme ref={aboutMeRef} />
      <Footer />
    </div>
  );
}

export default App;
