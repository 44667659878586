import React, { forwardRef, useEffect, useState } from 'react';

const Aboutme = forwardRef((props, ref) => {

  const [hasAnimated, setHasAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setTimeout(() => setHasAnimated(true), 500);
        }
      },
      { threshold: 0.3 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, hasAnimated]);

  return (
    <div ref={ref} className={`w-full lg:h-[850px] h-[800px] px-12 bg-blackk transition-opacity duration-0 ${
      isVisible ? 'opacity-100 animate-fadeIn' : 'opacity-0'
    } ${hasAnimated ? 'opacity-100' : ''}`}>
      <div className="w-full h-[800px] max-w-[1200px] flex flex-col items-center justify-center gap-10 rounded-lg p-5 mx-auto">
        <p className="text-white font-[gustavo-regular] text-2xl">
          ABOUT <span className="text-[#208474]">ME</span>
        </p>
        <p className="text-white font-[Montserrat] lg:text-xl text-sm mt-4 text-start">
          I am Ákos, a professional automotive photographer and videographer
          with a passion for capturing the artistry and performance of the
          world’s most exceptional cars. From timeless classics to cutting-edge
          hypercars, my work focuses on highlighting every detail that makes
          each vehicle unique.
        </p>
        <p className="text-white font-[Montserrat] lg:text-xl text-sm text-end">
          With a background in collaborating with prestigious brands 
          like Lamborghini and BMW, I aim to deliver images and films that reflect the
          same level of craftsmanship and precision as the cars themselves.
        </p>
        <p className="text-white font-[Montserrat] lg:text-xl text-sm text-start">
          Whether through stills or cinematic visuals, my goal is to create
          timeless pieces that resonate with car enthusiasts and elevate the
          perception of automotive excellence.
        </p>
      </div>
    </div>
  );
});

export default Aboutme;
