import React, { useState, useEffect } from 'react'
import ferrari from '../assets/ferrariYBC2_low.jpg'
import logo from '../assets/feherlogo.png'
import felirat from '../assets/felirat.png'
import { Cross as Hamburger } from 'hamburger-react'

function Hero({ onScrollToAboutMe, onScrollToBooking, onScrollToPhotography, onScrollToVideography }) {

  const [menuOpen, setMenuOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const toggleMenu = () => {
    if (menuOpen) {
      setIsAnimating(false);
      setTimeout(() => {
        setMenuOpen(false);
      }, 500);
    } else {
      setMenuOpen(true);
      setIsAnimating(true);
    }
  };


  const [fadeIn, setFadeIn] = useState(false);
  const [fadeIn2, setFadeIn2] = useState(false);
  const [fadeIn3, setFadeIn3] = useState(false);

  const [imageSrc, setImageSrc] = useState(ferrari);

  useEffect(() => {
    setTimeout(() => setFadeIn(true), 700);
    setTimeout(() => setFadeIn2(true), 300);
    setTimeout(() => setFadeIn3(true), 1000);
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [menuOpen]);


  const handleClickAboutMe = (e) => {
    e.preventDefault();
    setMenuOpen(false);
    setIsAnimating(false);
    onScrollToAboutMe(); 
  };

  const handleClickBooking = (e) => {
    e.preventDefault();
    setMenuOpen(false);
    setIsAnimating(false);
    onScrollToBooking(); 
  };

  const handleClickPhotography = (e) => {
    e.preventDefault();
    setMenuOpen(false);
    setIsAnimating(false);
    onScrollToPhotography(); 
  };

  const handleClickVideography = (e) => {
    e.preventDefault();
    setMenuOpen(false);
    setIsAnimating(false);
    onScrollToVideography(); 
  };

  return (
    <div className='relative h-100lvh  w-full bg-black'>
      <img src={imageSrc} alt="Ferrari" className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${fadeIn2 ? 'opacity-100' : 'opacity-0'}`}/>
      
      <div className={`relative z-10 w-full h-full flex flex-col justify-between`}>
        <div>
        <div className='w-full  h-[270px] bg-gradient-to-b from-black to-transparent flex justify-between items-start lg:px-44 px-10 select-none'>

          <div className='w-full max-w-[1200px] mx-auto flex items-center justify-between'>
          <img src={logo} alt="Logo" className={`h-[20px] mt-[55px] z-50 transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}/>
          
          <div className={`hidden md:flex space-x-8 mt-[55px] group transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
            <a href="#booking" onClick={onScrollToBooking} className='text-white cursor-pointer font-[gustavo-regular] transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>Booking</a>
            <a href="#photography" onClick={onScrollToPhotography} className='text-white cursor-pointer font-[gustavo-regular] transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>Photography</a>
            <a href="#videography" onClick={onScrollToVideography} className='text-white cursor-pointer font-[gustavo-regular] transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>Videography</a>
            <a href="#about-us" onClick={onScrollToAboutMe} className='text-white cursor-pointer font-[gustavo-regular] transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>About Me</a>
          </div>

          <div className={`md:hidden mt-[55px] cursor-pointer z-50 transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`} onClick={toggleMenu}>
            <Hamburger toggled={isAnimating} toggle={toggleMenu} color='#ffffff' rounded size={20} duration={0.8}/>
          </div>

          {menuOpen && (
            <div className={`fixed top-0 left-0 w-full h-screen  bg-black bg-opacity-20 backdrop-blur-lg flex flex-col justify-between pb-32 items-start z-40 px-10 transition-opacity duration-500 ease-in-out ${isAnimating ? 'animate-fadeIn' : 'animate-fadeOut'}`}>
              <div className="flex flex-col space-y-[-5px] mt-40 group ">
                <a href="#booking" onClick={ handleClickBooking } className='text-white text-[32px] font-semibold font-[gustavo-regular] cursor-pointer transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>BOOKING</a>
                <a href="#photography" onClick={ handleClickPhotography } className='text-white text-[32px] font-semibold font-[gustavo-regular] cursor-pointer transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>PHOTOGRAPHY</a>
                <a href="#videography" onClick={ handleClickVideography } className='text-white text-[32px] font-semibold font-[gustavo-regular] cursor-pointer transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>VIDEOGRAPHY</a>
                <a href="#about-us" onClick={ handleClickAboutMe } className='text-white text-[32px] font-semibold font-[gustavo-regular] cursor-pointer transition-opacity duration-300 ease-in-out group-hover:opacity-30 hover:!opacity-100'>ABOUT ME</a>
              </div>

              <div className='w-full h-[50px] flex flex-row items-center justify-center gap-10'>
                <a href="https://www.tiktok.com/@azureh_official" className='cursor-pointer'>
                  <svg width="26" height="26" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.321 5.562a5.124 5.124 0 0 1-.443-.258 6.229 6.229 0 0 1-1.137-.966c-.849-.971-1.166-1.956-1.282-2.645h.004c-.097-.573-.057-.943-.05-.943h-3.865v14.943c0 .2 0 .399-.008.595 0 .024-.003.046-.004.073 0 .01 0 .022-.003.033v.009a3.28 3.28 0 0 1-1.65 2.604 3.226 3.226 0 0 1-1.6.422c-1.8 0-3.26-1.468-3.26-3.281 0-1.814 1.46-3.282 3.26-3.282.341 0 .68.054 1.004.16l.005-3.936a7.178 7.178 0 0 0-5.532 1.62 7.583 7.583 0 0 0-1.655 2.04c-.163.281-.779 1.412-.853 3.246-.047 1.04.266 2.12.415 2.565v.01c.093.262.457 1.158 1.049 1.913a7.855 7.855 0 0 0 1.674 1.58v-.01l.009.01c1.87 1.27 3.945 1.187 3.945 1.187.359-.015 1.562 0 2.928-.647 1.515-.718 2.377-1.787 2.377-1.787a7.43 7.43 0 0 0 1.296-2.153c.35-.92.466-2.022.466-2.462V8.273c.047.028.672.441.672.441s.9.577 2.303.952c1.006.267 2.363.324 2.363.324V6.153c-.475.052-1.44-.098-2.429-.59Z"></path>
                  </svg>
                </a>
                
                <a href="https://www.instagram.com/azureh_official/" className='cursor-pointer'>
                  <svg width="26" height="26" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.375 3.25a4.388 4.388 0 0 1 4.375 4.375v8.75a4.388 4.388 0 0 1-4.375 4.375h-8.75a4.389 4.389 0 0 1-4.375-4.375v-8.75A4.388 4.388 0 0 1 7.625 3.25h8.75Zm0-1.75h-8.75C4.256 1.5 1.5 4.256 1.5 7.625v8.75c0 3.369 2.756 6.125 6.125 6.125h8.75c3.369 0 6.125-2.756 6.125-6.125v-8.75c0-3.369-2.756-6.125-6.125-6.125Z"></path>
                    <path d="M17.688 7.625a1.313 1.313 0 1 1 0-2.625 1.313 1.313 0 0 1 0 2.625Z"></path>
                    <path d="M12 8.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Zm0-1.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5Z"></path>
                  </svg>
                </a>
                

                <a href="https://www.facebook.com/profile.php?id=100084712407848" className='cursor-pointer'>
                  <svg width="26" height="26" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M22.5 12.063c0-5.799-4.702-10.5-10.5-10.5s-10.5 4.7-10.5 10.5c0 5.24 3.84 9.584 8.86 10.373v-7.337H7.692v-3.037h2.666V9.75c0-2.63 1.568-4.085 3.966-4.085 1.15 0 2.351.205 2.351.205v2.584h-1.324c-1.304 0-1.712.81-1.712 1.64v1.97h2.912l-.465 3.036H13.64v7.337c5.02-.788 8.859-5.131 8.859-10.373Z" clip-rule="evenodd"></path>
                  </svg>
                </a>
                
              </div>
            </div>
          )}
          </div>

          
        </div>
        <div className={`absolute lg:h-[120px] md:h-[90px] h-[60px] w-full overflow-hidden flex justify-center items-center lg:mt-[-70px] mt-[-90px] transition-opacity duration-1000 ${fadeIn3 ? 'opacity-100' : 'opacity-0'}`}>
          <img src={felirat} alt="" class="h-full w-auto object-contain" />
        </div>
        </div>
        
        <div className='w-full lg:h-[200px] h-[270px] bg-gradient-to-t from-black to-transparent flex justify-center items-center px-10'>
          <div className={`bottom-0 flex justify-center items-center h-[100px] lg:pb-0 pb-12 transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
              <svg width="20" height="20" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m5.25 12.563 6.75 6.75 6.75-6.75"></path>
                <path d="M12 18.375V4.687"></path>
              </svg>
          </div>
        </div>
        
        </div>
    </div>
  )
}

export default Hero
