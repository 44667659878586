import React, { forwardRef, useEffect, useState } from 'react';

const Booking = forwardRef((props, ref) => {

  const [hasAnimated, setHasAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setTimeout(() => setHasAnimated(true), 500);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, hasAnimated]);

  return (
    <div ref={ref} className={`w-full lg:h-[900px] h-[600px] px-12 bg-black transition-opacity duration-0 ${
      isVisible ? 'opacity-100 animate-fadeIn' : 'opacity-0'
    } ${hasAnimated ? 'opacity-100' : ''}`}>
      <div className='w-full lg:h-[800px] h-[500px] max-w-[1200px] flex flex-col items-center justify-center gap-10 rounded-lg p-5 mx-auto'>
        <p className='text-white font-[gustavo-regular] text-2xl'>BOOKING</p>
        <p className='text-white font-[Montserrat] lg:text-xl text-sm mt-4 text-center'>
          I provide tailored automotive photography sessions to meet your unique requirements.
        </p>
        <p className='text-white font-[Montserrat] lg:text-xl text-sm mt-4 text-center'>Please feel free to reach out to me via email at <a href="mailto:info@azureh.com" className='text-[#208474] ml-1'>info@azureh.com</a></p>
        <p className='text-white font-[Montserrat] lg:text-xl text-sm text-center'>or</p>
        <p className='text-white font-[Montserrat] lg:text-xl text-sm text-center'>send me a direct message on Instagram at <a href="https://instagram.com/azureh_official" className='text-[#208474] ml-1'>@azureh_official</a>.</p>
      </div>
    </div>
  )
});

export default Booking
