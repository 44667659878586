import React, { forwardRef, useEffect, useState } from 'react';

const Photography = forwardRef((props, ref) => {

  const [hasAnimated, setHasAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setTimeout(() => setHasAnimated(true), 500);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, hasAnimated]);

  return (
    <div ref={ref} className={`w-full lg:h-[600px] h-[450px] px-12 bg-black transition-opacity duration-0 ${
      isVisible ? 'opacity-100 animate-fadeIn' : 'opacity-0'
    } ${hasAnimated ? 'opacity-100' : ''}`}>
      <div className='w-full h-[400px] max-w-[1200px] flex flex-col items-center justify-center gap-10 rounded-lg p-5 mx-auto'>
        <p className='text-white font-[gustavo-regular] text-2xl'>PHOTOGRAPHY</p>
        <p className='text-white font-[Montserrat] lg:text-xl text-sm mt-4 text-start'>
          Photography is my passion, just as cars.<br></br> I specialize in capturing every detail, ensuring that each image reflects the true essence and character of your vehicle.<br></br> If you’re looking for photos you’ll never tire of admiring, you’ve come to the right place.<br></br><br></br> Let’s work together to <span className='text-[#208474]'>create something exceptional.</span>
        </p>
      </div>
    </div>
  )
});

export default Photography
